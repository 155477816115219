.social-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.social-icons-container a:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.social-icons-container a svg {
  width: 20px;
  height: 20px;
  fill: #333;
  transition: all 0.3s ease;
}

.social-icons-container a:hover svg {
  fill: #0077B5;
}
