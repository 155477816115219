.option-select {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
    font-size: 16px;
    padding: 30px;
    width: 200px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23aaa' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    margin-left: 5rem;
  }
  
  .option-select:hover {
    border-color: #aaa;
  }
  
  .option-select:focus {
    border-color: #1E90FF;
    box-shadow: 0 0 5px #1E90FF;
    outline: none;
  }
  
  .selected-component {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .selected-component h2 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #333;
  }
  