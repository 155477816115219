/* ContactUs.css */

.contact-containerss {
  text-align: center;
  position: relative;
display: flex;
}
.contact-imagee {
  max-width: 100%;
  height: 16rem;
  width: 100%;
  opacity: 10;
  /* background-color:  rgba(121, 114, 114, 0.7); */
}
.contact-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px; /* Adjust the font size as needed */
  color: white; /* Change the text color */
  padding: 5px 10px; /* Add padding for better visibility */
  font-weight: bolder;
  background:	#005978;
}
@media (max-width: 768px) {
  .contact-heading {
    font-size: 20px; /* Adjust the font size for mobile */
    padding: 5px 10px; /* Increase padding for better mobile visibility */
  }
}
.contcont {
  display: flex;
}
.imgg { 
  width: 100%;
  display: flex;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  height: 35rem;
  margin-top: -5px;
}
@media (max-width: 767px) {
  .imgg{
    width: 100%;
    height: 34rem;
  }
}
.image-container {
  width: 100%;
}
.conts {
  width: 60%;
  padding-bottom: 20px;
  }
.imgg{
  width: 100%;
  /* height: 34rem; */
}
.Pq{
  color: #005978;
  font-family: cursive;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
}
.contact-form {
    width: 60%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
    border: 0px solid #005978;
    border-radius: 0px;
    /* background-color:white; */
    display: flex;
    flex-direction: column;
    /* background: rgba(210, 200, 200, 0.6); */
    backdrop-filter: blur(10.7606px);
    border-radius: 5.22371px;
    /* animation: zoomIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards; */
  }
  @keyframes zoomIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  .form-title {
    font-weight: bolder;
    color: #005978;
    font-size: 30px;
    margin-bottom: 10px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  .form-label {
    font-size: 18px;
    color: #005978;
    display: block;
    margin-bottom: 10px;
  }
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    /* border: 2px solid 	#c4c4e9; */
    border-radius: 5px;
  }
  .form-textarea {
    height: 100px;
  }
  .form-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #d6d8f7;
  }
  .form-submit {
    background-color: #005978;
    color: #fff;
    padding: 5px 20px;
    border: 0;
    /* border-radius: 5px; */
    font-size: 20px;
    cursor: pointer;
  }
  .form-submit:hover {
    background-color:brown;
    /* color: brown; */
  }
  @media (max-width: 600px) {
    .conts{
      width:100%;

    }
  }
  @media (max-width: 767px) {
    .contact-form {
      width: 100%;
      max-width: 500px;
      height: auto;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .contact-form {
      width: 100%;
      max-width: 600px;
      height: auto;
    }
    
  }
  
  @media (min-width: 992px) {
    .contact-form {
      width: 60%;
      max-width: 700px;
      height: auto;
    }
  }
  /* ... (previous CSS code) */

.contact-info {
  width: 40%;
  padding-left: 100px;
  text-align: left;
  /* background-color: #f5f5f5; */
  border-radius: 5px;
  padding-top: 30px;
}

.contact-info h2 {
  font-size: 35px;
  color: #005978;
  margin-bottom: 25px;
  font-weight: bolder;
}
.connect-h{
  margin-top: 10px;
  font-size: 23px;
  color: #005978;
  margin-bottom: 15px;
  font-weight: bolder;
}
.contact-info p {
  font-size: 16px;
  color: #333;
  margin-bottom: 25px;
}

.social-links {
  margin-top: 25px;
}

.social-links a {
  margin-right: 20px;
  font-size: 25px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #FF5733; /* Change the hover color as needed */
}

/* Style the Facebook icon */
.fa-facebook:before {
  content: '\f09a';
  color: #1877F2; /* Facebook Blue */
}

/* Style the Twitter icon */
.fa-twitter:before {
  content: '\f099';
  color: #1DA1F2; /* Twitter Blue */
}

/* Style the LinkedIn icon */
.fa-linkedin:before {
  content: '\f0e1';
  color: #0077B5; /* LinkedIn Blue */
}
.fa-instagram:before {
  content: '\f16d';
  color: #E1306C; /* Instagram Pink */
}

/* Style the YouTube icon */
.fa-youtube:before {
  content: '\f167';
  color: #FF0000; /* YouTube Red */
}
/* Add more styles for other social media icons as needed */
/* ... (previous CSS code) */

/* Add a media query for screens smaller than 768px (adjust as needed) */
@media (max-width: 768px) {
  .contcont {
    flex-direction: column; /* Stack elements vertically on smaller screens */
  }

  .contact-info {
    width: 100%; /* Make contact info take full width on small screens */
    float: none; /* Remove the float */
    margin-bottom: 20px; /* Add some space between elements */
    padding: 20px;
  }

  .form-container {
    width: 100%; /* Make the form take full width on small screens */
  }
}
/* MapSection.css */
.map-container {
  width: 100%;
  max-width:100%; /* Adjust to your desired width */
  margin: 0 auto;
}
.location-heading{
  margin-top: 10px;
  font-size: 35px;
  color:white;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bolder;
}
@media (max-width: 767px) {
  .location-heading {
    font-size: 20px; /* Adjust font size for mobile */
    margin-top: 5px; /* Adjust margin-top for mobile */
    margin-bottom: 5px; /* Adjust margin-bottom for mobile */
  }
}