.training-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
     background-color:rgba(0, 0, 0, 0.842);
    font-family: Poppins,sans-serif;
    box-sizing: border-box;
    max-width: 100%;
    }
    .training-h2{
        color: rgb(246, 242, 9);
        font-family: Poppins,sans-serif;
        text-align: center;
        font-size: 2.5rem;
        margin-top: 40px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      @media (max-width:600px) {
        .training-h2{
          font-size: 1.5rem;
           text-align:center;
          margin-top: 0px;
          font-weight: bold;
          font-family: Poppins,sans-serif;
        }
      }
  .training-p{
    margin: 20px;
    font-size: large;
    font-weight: bold;
    color: white;
  }
  .training-start {
    text-align: center;
    margin-top: 40px;
    flex: 1;
    margin-right: 20px;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    width: 100%;
  }
  h1{
    margin: 0;
    font-size: 48px;
    font-weight: bold;
    font-family: Poppins, sans-serif;
  }
  p{
    font-size: 18px;
    line-height: 1.5;
  }
  .training-image-container {
    flex: 1;
  }
  .training-img{ 
    display: block;
    max-width: 100%;
    height:22rem;
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    .training-img{
      display: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .training-container{
      flex-direction: column;
    }
    .training-start{
      width: 100%;
      
    }
    
  }
  .training-button{
    background-color: #005978;
      color: whitesmoke;
      border: none;
      border-radius: 4px;
      padding: 15px 30px;
      cursor: pointer;
      font-size: 16px;
      margin-top: 0px;
      margin-bottom: 0px;
      position: relative;
      overflow: hidden;
  }
  .training-button:hover{
    background-color:brown;
  }
  .cards-row {
    display: flex;
    justify-content: center; /* Center the cards horizontally */
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Enable snap-scrolling in the x direction */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
    margin-top: 20px;
  }
  
  .Training-cards {
    flex: 0 0 auto;
    width: 300px;
    height: 400px;
    background-color: #fff; /* Change the background color to a lighter shade */
    margin: 15px;
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow effect */
    overflow: hidden; /* Ensure content doesn't overflow */
    transition: transform 0.2s; /* Add a subtle hover effect */
    position: relative;
  }
  
  .Training-cards:hover {
    transform: scale(1.05); /* Enlarge the card on hover */
  }
  
  .Training-cards img {
    width: 100%;
    height: 100%; /* Adjust the image height */
    object-fit: cover;
  }
  
  .Training-cards .names {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for text */
    color: #fff;
    padding: 8px 0;
    font-size: 20px;
    text-align: center;
    border-bottom-left-radius: 10px; /* Add rounded corners to the bottom */
    border-bottom-right-radius: 10px;
    transition: background-color 0.2s; /* Smooth background color transition */
  }
  
  .Training-cards:hover .names {
    background-color: rgba(0, 0, 0, 0.9); /* Darken the background on hover */
  }
  
  
  @media screen and (max-width: 600px) {
    .cards-row {
      justify-content: flex-start; /* Align cards to the start on smaller screens */
      flex-wrap: nowrap; /* Prevent card wrapping on smaller screens */
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .soci{
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #005978;
    font-family: Poppins,sans-serif;
  }