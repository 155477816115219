.dropdown {
    font-size: 16px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #555;
    appearance: none;
    width: 200px;
    transition: all 0.2s;
  }
  
  .dropdown:hover {
    border-color: #999;
  }
  
  .dropdown:focus {
    outline: none;
    border-color: #666;
  }
  
  .dropdown option {
    font-size: 16px;
    color: #333;
  }

  /* General styling for the form container */
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

form {
  width: 100%;
  max-width: 600px; /* Responsive max-width for better UX on larger screens */
  display: flex;
  flex-direction: column;
  gap: 16px; /* Space between fields */
}

/* Style for form labels */
p {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

/* Style for text fields */
input[type="text"],
input[type="file"] {
  width: 100%;
  padding: 10px 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Focus state for inputs */
input[type="text"]:focus,
input[type="file"]:focus {
  border-color: #005978;
  box-shadow: 0 0 4px rgba(0, 89, 120, 0.5);
}

/* Style for the button */
button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #005978;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #0079a1;
  box-shadow: 0 4px 6px rgba(0, 89, 120, 0.3);
}

button:active {
  background-color: #004d63;
}

/* Responsive design */
@media (max-width: 768px) {
  form {
    padding: 10px;
  }

  input[type="text"],
  input[type="file"] {
    font-size: 14px;
  }

  button {
    font-size: 14px;
  }
}

  