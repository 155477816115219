.property-form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s;
}

.property-form-container:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
}

.calculator-title {
  font-size: 24px;
  margin: 10px 0;
  color: #007bff;
}

.input-group {
  margin: 10px 0;
  transition: transform 0.2s;
}

.input-group:hover {
  transform: scale(1.03);
  transition: transform 0.2s;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s, transform 0.2s;
}

input:hover {
  border-color: #007bff;
  transform: scale(1.03);
}

label {
  display: block;
  text-align: left;
  margin: 10px 0;
  font-weight: bold;
  color: #333;
}

.calculate-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.calculate-button:hover {
  background-color: #0056b3;
  transform: scale(1.03);
}

.result {
  margin: 20px 0;
}

.result h2 {
  font-size: 18px;
}

.result p {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}
