.listing-card {
  background-color: #eff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 16px;
  width: 100%;
  max-width: 21rem;
  margin-right: 16px;
  margin-bottom: 16px;
  transition: box-shadow 200ms ease-in-out;
}
.listing-card:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
}
.listing-image-container {
  position: relative;
  display: inline-block;
}
.listing-text-box {
  position: absolute;
  top: 60px;
  left: 0;
  padding: 0.1rem;
  background-color: rgba(255, 255, 255, 0.7);
  writing-mode: vertical-rl;
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;

}

.listing-text-box a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(92, 36, 36);
}

.listing-text {
  margin-left: 0.5rem;
}
@media only screen and (max-width: 768px) {
  .listing-image-container{
    width: 100%;
  }
  .listing-text-box {
    top: 20px;
    font-size: 0.6rem;
   
  }
  
  .listing-text-box a {
    font-size: 0.7rem;
  }
}
.listing-image-icons {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: row;
}
.listing-wishlist-icon.active {
  color: red;
}

.listing-image-icons button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 1px;
  color: white;
}

.listing-image-icons button:hover {
  color:red;
}
.listing-image {
  width: 100%;
  height: 16rem;
  object-fit: fill;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: transform 200ms ease-in-out;
}

.listing-image:hover {
  transform: scale(1.05);
}

.listing-details {
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  margin-bottom: -15px;
}
.listing-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.listing-name {
  background-color: #005978;
  color: #fff;
  font-size: 12px;
  padding: 4px 10px;
  margin-bottom:20px;
  flex: 1;
  text-align:center;
}
.listing-wh{
  color: #fff;
  font-size: 13px;
  padding: 4px 8px;
  
  margin-bottom: 20px;
  flex: 1;
  text-align:end;
}

.listing-type {
  color: #005978;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 4px;
  flex: 1;
  text-align: right;
  margin-top: 4px
}

.listing-location {
  display: flex;
   text-align: start;
  color: #777;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;


}

.listing-location svg {
  width: 20px;
  height: 20px;
  text-align: start;
}
.listing-brochure{
  display: flex;
  text-align:right;
  color: #777;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 0px;
}
.pdf-icon{
  margin-right: 4px;
}
.brochure-container {
  border: 1px solid #ccc;
  padding: 6px;
}

.listing-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.listing-price {
  text-align: start;
  font-size: 12px;
  font-weight: 700;
  color: #005978;
  flex: 1;
  margin-left: 4px;
}

.listing-status {
  font-size: 12px;
  font-weight: 650;
  color: #008000;
  flex: 1;
  text-align: right;
  margin-bottom: 8px;
}

.listing-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.whatsapp-icon {
  padding: 0.5px;
  background-color: #008000;
  color: white;
  transition: transform 200ms ease-in-out;
}
.whatsapp-icon:hover {
  transform: scale(1.1);
}
.enquire {
  padding: 6px 10px;
  font-size: 12px;
  text-transform: uppercase;
  transition: background-color 200ms ease-in-out;

}

.enquire:hover {
  background-color: #004c5f;
}

@media screen and (max-width: 768px) {
  .listing-card {
    margin-right: 0;
    width: 100%;
    max-width: none;
  }

  .listing-image {
    height: 12rem;
    margin-bottom: 4px;
  }

  .listing-name {
    font-size: 14px;
    padding: 2px 8px;

    margin-bottom: 2px;
  }

  .listing-type {
    font-size: 1px;
    margin-bottom:2px
  }
  .listing-location {
    font-size: 12px;
    }
    
    .listing-location svg {
    width: 16px;
    height: 16px;
    margin-right: 2px;
    }
    
    .listing-price {
    font-size: 12px;
    }
    
    .listing-status {
    font-size: 12px;
    }
    
    .enquire {
    font-size: 12px;
    padding: 4px 8px;
    }
    }
    
    @media screen and (max-width: 480px) {
    .listing-image {
    height: 10rem;
    }
    
    .listing-name {
    font-size: 12px;
    padding: 2px 4px;
    margin-bottom: 20px;
   }
    
    .listing-type {
    font-size: 16px;
    }
    
    .listing-location {
    font-size: 14px;
    }
    
    .listing-location svg {
    width: 14px;
    height: 14px;
    margin-right: 2px;
    }
    
    .listing-price {
    font-size: 14px;
    }
    
    .listing-status {
    font-size: 14px;
    }
    
    .enquire {
    font-size: 14px;
    padding: 2px 8px;
  
    }
    }
    