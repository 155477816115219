* {
    box-sizing: border-box;
  }
  
  .App {
    width: 70vh;
    margin: 5rem auto;
    display: flex;
    justify-content: left;
  }

  .form-field {
    flex: 2;
  }
  .output {
    margin-left: 3rem;
    flex: 1;
  }
  .form-field label,
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: rgb(195, 142, 142);
  }
  input {
    height: 35px;
    width: 100%;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
  }
  
  input:focus {
    border: 1px solid rgb(0, 208, 255);
  }
  
  .services input[type="text"] {
    width: 80%;
    margin-right: 10px;
  }  
  .first-division {
    display: flex;
    flex-direction: column;
    margin: 0 5px 0.5rem 0;
    width: 100%;
  }
  button {
    background: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 2px;
  
  }
  .first-division button {
    align-self: flex-start;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    color: rgb(0, 208, 255);
    border: 1px solid rgb(0, 208, 255);
  } 
  .second-division button {
    color: red;
    border: 1px solid red;
  }
  