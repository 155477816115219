.whyy{
  color: #005978;
    font-family: Poppins,sans-serif;
    text-align: center;
    font-size: 1.9rem;
    margin-top: 60px;
    font-weight: bold;
    background-color: rgb(210, 230, 248);
}
@media (max-width:600px) {
  .whyy{
    font-size: 1.3rem;
     text-align:center;
     background-color: aliceblue;
    margin-top: 40px;
    font-weight: bold;
    font-family: Poppins,sans-serif;
  }
}
.headlines {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  
  .headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    max-width: 300px;
  }

  .headline img {
    width: 100%;
    max-width: 100px;
    margin-bottom: 1rem;
  }
  
  .headline h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: center;
    color: #005978;
    font-family: Poppins,sans-serif;
  }
  
  .headline p {
    font-size: 1.1rem;
    text-align: center;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .headlines {
      flex-direction: column;
      align-items: center;
    }
  
    .headline {
      margin-bottom: 4rem;
    }
  }
  
  /* Animations */
  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes slideInDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .trust {
    animation: slideInLeft 1.5s
  }
  .best {
    animation: slideInRight 1.5s;
    }
    
    .quick {
    animation: slideInUp 1.5s;
    }
    
    .unique {
    
    animation: slideInDown 1.5s;
    }  