.dream{
  color: #005978;
  font-family: Poppins,sans-serif;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 40px;
  font-weight: bold;
  background-color: rgb(208, 231, 250);
  margin-bottom: 8px;
}
@media (max-width:600px) {
  .dream{
    font-size: 1.5rem;
     text-align:center;
     background-color: aliceblue;
    margin-top: 20px;
    font-weight: bold;
    font-family: Poppins,sans-serif;
  }
}
.blinking-text {
  font-size: 2rem;
  text-align: center;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.city-list {
  display: flex;
  flex-wrap: wrap;
  margin: 40px;
  justify-content: center;
  margin-top: 2rem;
  
}
.city-box {
  margin: 1rem;
  padding: 1rem;
  background-color:rgb(232, 251, 251);
  border-radius: 0.5rem;
  text-align: center;
  width: 150px;
  height: 200px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  min-width: 150px; /* Added min-width */
  min-height: 200px; /* Added min-height */
}

.city-box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.city-image {
  max-width: 100%;
  height: 20px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  min-width: 100px; /* Added min-width */
  min-height: 120px; /* Added min-height */
}

.city-name {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #005978;
}

@media screen and (max-width: 768px) {
  .city-list {
  justify-content: center;
  }
  
  .city-box {
  margin: 0.5rem;
  width: 40%;
  height: auto;
  padding-bottom: 40px;
  }
  
  .city-image {
  height: 100%; /* Set height to 100% to fill the container */
  width: 100%; /*Set width to 100% to fill the container */
  object-fit: cover;  /* This property scales the image to fill the container while maintaining its aspect ratio */
  }
  
  .city-name {
  font-size: 0.8rem;
  }
  }

@media screen and (max-width: 480px) {
  .city-list {
    display: flex;
    flex-wrap: nowrap; /* Prevents wrapping */
    overflow-x: auto; /* Enables horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Improves scrolling performance on iOS */
    scroll-snap-type: x mandatory; /* Enables snapping */
    padding-left: 1rem; /* Adds left padding */
  }

  .city-box {
    width: calc(50% - 1rem); /* Adjusted width to fit two boxes in a row */
    min-width: unset; /* Removed min-width */
    margin-right: 0.5rem; /* Adds right margin to separate boxes */
    scroll-snap-align: start; /* Snaps to the start of each box */
  }

  .city-box:first-of-type {
    margin-left:100rem; /* Brings first box back to the left edge */
  }

  .city-image {
  height: 20px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  }

  .city-name {
    font-size: 0.7rem;
  }
}
