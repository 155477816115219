.dropdown {
    font-size: 16px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #555;
    appearance: none;
    width: 200px;
    transition: all 0.2s;
  }
  
  .dropdown:hover {
    border-color: #999;
  }
  
  .dropdown:focus {
    outline: none;
    border-color: #666;
  }
  
  .dropdown option {
    font-size: 16px;
    color: #333;
  }
  