.search-heading{
    font-size: 25px;
    color: black;
    padding-bottom: 10px;
    text-align: center;
    font-weight: lighter;
    line-height: normal;
    height: 74px;
    line-height: 66px;
}
.heading-span{
    font-family: "forte";
    font-size: 33px;
    color: red;
}

.searchbox {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 5px;
    /* justify-content: center; */
    align-items: center;
    /* text-align: center; */
    padding: 5px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #004a65;
    box-shadow: 0px 0px 10px 0px #ccc;
    border-radius: 10px;
  }

  .renderbuttons{
    display: flex;
    flex-direction: row;
    padding: 30px;
    color: black;
    background-color: transparent;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
  }

  .search-box-button { /* Style for the buttons inside serachbox */
    color: black; /* Black text */
    background-color: transparent; /* Transparent background */
    padding: 0.5rem 1rem; /* Adjust padding as needed */
    border: none; /* Remove default border */
    cursor: pointer; /* Make it look clickable */
    margin: 0 0.5rem; /* Add some spacing between buttons if needed */
    border-radius: 5px; /* Add rounded corners if you want */
    transition: background-color 0.3s ease; /* Add a smooth transition for hover effect */
  }


  /* @media only screen and (min-width:1300px) and (max-width: 3900px){
    .search-main{
      display: flex;
      flex-direction: row;
      max-width: 100%;
      justify-content: center;
    }
    .search-heading{
      max-width: 100%;
      text-align: center;
    }
    .searchbox{
      max-width: 100%;
      justify-content: center;
    }
    .search-container{
      max-width: 100%;
    }
  } */

  @media only screen and (min-width:920px) and (max-width: 1300px){
    .search-main{
      display: flex;
      max-width: 100%;
      justify-content: center;
    }
    .search-heading{
      max-width: 100%;
      text-align: center;
    }
    .searchbox{
      max-width: 100%;
      justify-content: center;
    }
    .renderbuttons{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .search-container{
      max-width: 100%;
    }
  }

  
@media only screen and (min-width: 700px) and (max-width: 920px){
  .search-main{
    display: flex;
    max-width: 100%;
    justify-content: center;
  }
  .search-heading{
    max-width: 100%;
    /* margin-bottom: 150px; */
    text-align: center;
  }
  .searchbox{
    display: flex;
    flex-direction: column;
    padding: 5px;
    max-width: 100%;
    justify-content: center;
  }
  .search-container{
    max-width: 480px;
  }
  
  .renderbuttons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .search-btn{
    max-width: 50px;
    padding: 2px;
  }
  .search-icon{
    font-size: 35px;
  }
  .search-btn span{
    display: none;
  }
}

@media only screen and (max-width: 700px){
  .search-main{
    display: flex;
    max-width: 100%;
    justify-content: center;
  }
  .search-heading{
    max-width: 400px;
    margin-bottom: 70px;
    text-align: center;
    font-size: 25px;
    color: black;
    padding-bottom: 10px;
    font-weight: bolder;
    line-height: normal;
    height: 74px;
    line-height: 66px;
  }
.heading-span{
    font-family: "forte";
    font-size: 33px;
    color: red;
}

  .searchbox{
    display: flex;
    flex-direction: column;
    padding: 5px;
    max-width: 450px;
    justify-content: center;
  }
  .renderbuttons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .search-container{
    max-width: 350px;
  }
  .search-btn{
    max-width: 50px;
    padding: 2px;
  }
  .search-btn span{
    display: none;
  } 
  .search-icon{
    font-size: 35px;
  }
}
@media only screen and (max-width: 463px){
  .search-main{
    display: flex;
    max-width: 100%;
    justify-content: center;
  }
  .search-heading{
    max-width: 400px;
    margin-bottom: 70px;
    text-align: center;
    font-size: 25px;
    color: black;
    padding-bottom: 10px;
    font-weight: bolder;
    line-height: normal;
    height: 74px;
    line-height: 66px;
  }
  .heading-span{
    font-family: "forte";
    font-size: 33px;
    color: red;
}

  .searchbox{
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px;
    max-width: 400px;
    justify-content: center;
  }
  .search-container{
    max-width: 350px;
  }
  
  .search-btn{
    max-width: 50px;
    padding: 2px;
  }
  .search-icon{
    font-size: 35px;
  }
}

