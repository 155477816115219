.mHeader1 {
    color: #10101058;
    font-size: 14px;
}

abbr, acronym, address, blockquote, button, caption, cite, code, dd, del, dfn, dl, dt, em, fieldset, form, h1, h2, h3, h4, h5, h6, img, label, legend, li, ol, p, pre, q, s, samp, small, strike, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, ul, var {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: 400;
    font-style: normal;
    font-size: 100%;
    font-family: inherit;
}

body, ul, li, ul li, ol, table, tr, tbody, td, h1, h2, h3, h4, h5, h6, form, input, select {
    margin: 0px;
    padding: 0;
    font-family: 'open_sansregular', Tahoma, Geneva, sans-serif;
    list-style-type: none;
}

.mHeader1 .highLight {
    color: #303030;
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 10px;
}

/* SellForm.css */

form {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  form h1 {
    margin-bottom: 20px;
    font-size: 1.5em;
    text-align: left;
  }
  
  .MuiTextField-root {
    margin-bottom: 15px;
    width: 100%;
  }
  
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #1976d2; /* Change this color to your desired focus border color */
    }
    &.Mui-error fieldset {
      border-color: #f44336; /* Change this color to your desired error border color */
    }
    fieldset {
      border-width: 2px; /* Set the border width */
      border-radius: 5px; /*Set the border radius*/
    }
    &:hover fieldset {
      border-color: #1565c0; /* Change this color to your desired hover border color */
    }
    &.Mui-focused fieldset {
      border-color: #1976d2; /* Change this color to your desired focus border color */
    }
    &.Mui-error fieldset {
      border-color: #f44336; /* Change this color to your desired error border color */
    }
  }
  
  .MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.54);
    &.Mui-focused {
      color: #1976d2;
    }
  }
  
  .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #1976d2;
    }
  
  
  .MuiOutlinedInput-notchedOutline {
    transition: border-color 0.2s;
  }
  
  .textfield {
    border: 0px;
  }