.property-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  margin-top: 0px;
  padding: 25px;
}
.display-copy{
  user-select: none;
}
.property-image {
    width: 50%;
    position: relative;
    margin-right: 20px;
  }
  .property-image img {
    width: 100%;
    height: 22rem;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }

  .property-image img:hover {
    transform: scale(1.1);
  }
  .slick-arrow {
    color: rgb(223, 19, 19);
  }
  .slick-arrow:hover {
    visibility: visible;
    color: rgb(236, 26, 26);

  }
  .wishlist-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
  }

  .share-button{
    position: absolute;
    top: 5px;
    color: white;
    right: 40px;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
  }
  .listing-text-boxx {
    position: absolute;
    top: 100px;
    left: 0;
    padding: 0.1rem;
    background-color: rgba(255, 255, 255, 0.7);
    writing-mode: vertical-rl;
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
  
  }
  
  .listing-text-boxx a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgb(92, 36, 36);
  }
  
  .listing-text {
    margin-left: 0.5rem;
  }
  @media only screen and (max-width: 768px) {
    .listing-text-boxx {
      top: 60px;
      font-size: 0.6rem;
     
    }
    
    .listing-text-boxx a {
      font-size: 0.7rem;
    }
  }
  .property-details {
    width: 55%;
    padding: 20px 20px;
  }
  .whatsapp-iconn {
    color: green;
    font-size: 50px;
    border: 2px solid green; /* Add a border to the icon */
    border-radius: 50%; /* Make the icon circular */
    margin-left: 100px; /* Add margin to the right of the icon */
    padding: 5px; /* Add padding to the icon */
    transition: all 0.3s ease; /* Add transition effect to the icon */
  }
  
  .whatsapp-iconn:hover {
    background-color: green; /* Change the background color on hover */
    color: white; /* Change the text color on hover */
  }
  
  .name{
    font-size: 1.5rem;
    margin-bottom: 20px;
    line-height: 1.3;
    font-family: Poppins,sans-serif;
    transition: color 0.2s ease-in-out;
    font-weight: bold;
    color: black;
  }
  
  .name:hover {
    color: #005978;
  }
  .ca{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #005978;
    border-radius: 0.25rem;
    transition: transform 0.5s;

}
.Projj{
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
}
  .ca:hover {
    animation: shake .5s;
  }
  
  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }
  .shake {
    animation-duration:1s;
    animation-iteration-count: infinite;
  }
  
  .property-price {
    font-weight: bold;
    margin: 0;
    font-size: 1.5rem;
    margin-top: 10px;
    color: #c95128;
    transition: color 0.2s ease-in-out;
  }
  
  .property-price:hover {
    color: #005978;
  }
  
  .price-value {
    font-weight: bold;
    color: #c95128;
  }
  
  @media (max-width: 768px) {
    .property-card {
      flex-wrap: wrap;
    }
  
    .property-image {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    .property-image img {
        width: 100%;
        height: 100%;
      }
    .property-details {
      width: 100%;
      padding: 10px;
      font-family: Poppins,sans-serif;
    }
  }
  .property-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items:flex-start;
    margin-top: 20px;
    
  }
  
  .property-info p {
    flex-basis: 48%;
    margin-bottom: 10px;
  }
  
  .property-info span {
    font-weight: bold;
    color: #005978;
  }
  
  @media (max-width: 768px) {
    .property-info {
      flex-direction: column;
    }
    
    .property-info p {
      flex-basis: 100%;
    }
  }
  table {
    border-collapse: collapse;
    position: relative;
      width: 60%;
      height: 200px;
      margin: 20px auto;
      padding: 10px;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  td, th {
    border: 0.5px solid #005978;
    padding: 10px;
    text-align:center;
    
  }
  
  th {
    background-color: rgb(240, 236, 236);
    color: #005978;
    
  }
  .sp{
    color: #005978;
    font-family: Poppins,sans-serif;
    text-align: center;
    font-size: 1.9rem;
    margin-top: 60px;
    font-weight: bold;
    background-color: rgb(188, 221, 248);
  
 }
 @media (max-width:600px) {
    .sp{
      font-size: 1.3rem;
       text-align:center;
       background-color: rgb(215, 232, 247);
      margin-top: 40px;
      font-weight: bold;
      font-family: Poppins,sans-serif;
    }
    table {
      font-size: 12px;
      width:auto;
      height: auto;
      align-items: center;
    
    }
   td, td {
      padding: 3px;
    }
  }
  @media (max-width: 600px) {
    .am{
      font-size: 1.5rem;
      text-align: center;
      margin-top: 10px;
      font-weight: bold;
      font-family: Poppins,sans-serif;
    }
  }
    
/* Style the amenities container */
.am {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: 15px;
  }

/* Style the amenity boxes */
.amenity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin:30px;
  padding: 2px;
  transition: all 0.3s ease-in-out;
}

/* Style the amenity images */
.amenity img {
  width: 30%;
  height: auto;
  margin-bottom: 10px;
}

/* Style the amenity titles */
.amenity p {
  font-size: 1rem;
  font-weight:600;
  text-align: center;
  color:#005978;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  /* Reduce the size of the amenity boxes */
  .amenity {
    width: 100px;
    height: 100px;
    margin: 10px;
    padding: 5px;
  }

  /* Reduce the size of the amenity images */
  .amenity img {
    width: 30%;
    height: auto;
    margin-bottom: 5px;
  }

  /* Reduce the font size of the amenity titles */
  .amenity p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .amenity {
    width: 100px;
    height: 100px;
    margin: 3px;
    padding: 10px;
    }
    
    /* Further reduce the size of the amenity images */
    .amenity img {
    width: 30%;
    margin-bottom: 2px;
    }
    
    /* Further reduce the font size of the amenity titles */
    .amenity p {
    font-size: 0.8rem;
    }
    }
    
    /* Add hover effect to amenity boxes */
    .amenity:hover {
    transform: scale(1.05);
    background-color:#f6f1f1;
    box-shadow: 0 0 20px green;
    }
    
    /* Add transition to amenity images */
    .amenity img {
    transition: all 0.3s ease-in-out;
    }
    .map-container {
      position: relative;
      width: 80%;
      height: 300px;
      margin: 20px auto;
      padding: 10px;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
    
    .map-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
    
    
    @media screen and (min-width: 768px) {
      .map-container {
        height: 500px;
        margin-bottom: 40px;
      }
    }
    
    #my-iframe {
      width: 100%;
      max-width: 800px;
      height: 500px;
      border: 2px solid #ccc;
      background-color: hsl(0, 0%, 97%);
      margin: 0 auto;
      transform: rotate(0);
      animation: rotate 1s ease-out forwards;
      
    }
    @keyframes rotate {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
    
    
    @media screen and (max-width: 767px) {
      #my-iframe {
        height: 300px;
      }
    }
    
    @keyframes slide-in {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }
 /* BhutaniProjects.css */
 .bhutani-projects-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 20px;
  background-color: #f8f8f8;
}

.bhutani-logo {
  width: 200px;
  height: auto;
  margin-right: 20px;

}

.bhutani-projects-info {
  flex: 1;
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.bhutani-projects-info h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  margin-right: 20px;
}
.bhutani-projects-info p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 20px;
}
.expe{
  font-size: 20px;
  color:#c95128;
  line-height: 1.5;
}

@media only screen and (max-width: 768px) {
  .bhutani-projects-container {
    flex-direction: column;
  }
  
  .bhutani-logo-container {
    margin-bottom: 20px;
  }
  
  .bhutani-data {
    margin: 10px 0;
  }
  
  h2 {
    font-size: 24px;
  }
  
  p {
    font-size: 16px;
  }
}

.pdf-document {
  width: 100%;
  height: 100%;
}

.pdf-page {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  transform-origin: 0 50%;
  transition: transform 0.6s;
  background: white;
}

.pdf-page:focus {
  outline: none;
}

.pdf-page:nth-of-type(odd) {
  transform: perspective(1000px) rotateY(20deg);
}

.pdf-page:nth-of-type(even) {
  transform: perspective(1000px) rotateY(-20deg);
}

.pdf-page:focus {
  transform: scale(1.1);
}
.flipbook-container {
  height: 600px; /* Set your desired height here */
}