/* HeroSlider.css */
.hero-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  background-size: cover; /* or contain */
  background-position: center;
}

.slide.active {
  opacity: 1;
}

.slide img,
.slide video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* or contain */
}

.navigation {
  /* ... (styles for navigation buttons) */
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex; /* or inline-block if you prefer */
}

.navigation button {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .hero-slider {
    display: block;
    /* border-radius: 0px; */
    max-width: 100%;
    margin-left: 0px;
    margin-top: 0px;
  }
  .hero-imgcont{
    max-height: max-content;
  }

@media only screen and (min-width:100px) and (max-width: 700px) {
  .hero-slider {
    display: block;
    /* border-radius: 0px; */
    max-width: 100%;
    margin-left: 0px;
    margin-top: 0px;
  }
  .hero-imgcont{
    min-width: 100%;
  }
}
}
