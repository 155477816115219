.interanimated{
    font-family: Arial, sans-serif;
    font-size:25px;
    font-weight: bold;
    text-align: center;
    color: #005978;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .intfo{
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
     width:400px;
     margin-bottom: 30px;
    margin-right: 60px;
    display: flex;
    flex-direction: column;
    background: rgba(243, 243, 243, 0.6);
    backdrop-filter: blur(1.7606px);
    border-radius: 5.22371px;
    border: 2px solid #005978;
    border-radius: 5px;
    animation: zoomIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.intimg{
  background-image: url('../assets/svg/homedesign.jpeg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}
.intform{
  padding: 20px;
} 
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input,
  select,
  textarea {
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;

  }
  .intbutton{
    background-color: #005978;
    color: whitesmoke;
    border: none;
    border-radius: 4px;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
  }
  
  .intbutton:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -1;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }
  
  .intbutton:hover:before {
    transform: translateX(0);
  }
  
  .intbutton:hover {
    background-color: rgb(176, 13, 13);
    color: white;
  }
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 10px;
    margin-left: 5px;
  }
  
  @media (max-width: 768px) {
    .intfo{
      padding: 10px;
      width: 80%;
    }
  }
  input:focus {
    border: 2px solid #007bff;
    box-shadow: 0px 0px 5px #007bff;
  }
  @media (max-width: 600px) {
    .intform{
      max-width: 100%;
      padding: 10px;
    }
    
    input[type="text"],
    input[type="tel"],
    select,
    textarea {
      font-size: 14px;
    }
    
    .sharebutton[type="submit"] {
      font-size: 14px;
    }
  }
  .home-container {
    position: relative;
    width: 100%;
    height: 50vh;
    background-image: url("../assets/svg/homeimg.jpeg");
    background-size: cover;
    background-position: center;
   

  }
  
  .overlay {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ef1a1a;
  }
  
  .homeh2{
    font-size: 3rem;
    margin-bottom: 1rem;
    color:whitesmoke;
  }
  
  .homep{
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .homebutton {
    background-color: #005978;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .homebutton:hover {
    background-color: #0062cc;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }
  @media only screen and (max-width: 767px) {
    .overlay {
      top: 50%;
      font-size: 1.5rem;
    }
    
    .homeh2 {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
    
    .homep {
      font-size: 1rem;
      margin-bottom: 1rem;
      font-weight: bold;
    }
    
    .homebutton {
      font-size: 1rem;
    }
  }
  .electriccontainer {
    background-color: aquamarine;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
  }
  
  .electriccontent {
    flex: 1;
    padding-right: 20px;
    margin-left: 30px;
  }
  
  .electricimage {
    flex: 1;
    text-align: center;

  }
  .imm {
    max-width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 768px) {
    .electriccontainer {
      flex-direction: column;
    }
    .electriccontent {
      margin-bottom: 20px;
    }
    .electricimage {
      display: block;
      margin-top: 20px;
    }
  }
  .homebuttonn{
    background-color: #005978;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    display: block;
  margin: 0 auto;
  margin-top: 5px
  }
  
  .homebuttonn:hover {
    background-color: rgb(186, 43, 43);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }