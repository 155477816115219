.p-span {
  font-family: "forte";
  font-size: 23px;
  color: red;
}

@media only screen and (min-width: 773px) and (max-width: 1350px) {
  .yt-section {
    max-width: 100%;
    justify-content: space-around;
    align-items: center;
    display: block;
  }
  .yt-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;
  }
  .yt-h1 {
    text-align: center;
    font-size: 40px;
    font-weight: bolder;
    min-width: 100%;
  }
  .yt-p {
    display: none;
  }
  .yt-btn {
    align-items: center;
    max-width: 250px;
    margin-left: 250px;
  }
  .p-span {
    text-align: center;
  }
  .ytslider {
    margin-top: 0px;
    margin-bottom: 20px;
    height: 300px;
  }
  .hero-imgcont {
    min-width: 100%;
  }
}

@media only screen and (max-width: 773px) {
  .yt-section {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .yt-box {
    display: inline-block;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    /* margin: 30px; */
  }
  .yt-h1 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    line-height: normal;
  }
  .yt-p {
    display: none;
  }
  .yt-h1 .p-span {
    text-align: center;
    padding: 5px;
    color: rgb(213, 45, 45);
    border-radius: 100%;
    font-size: 20px;
    margin-top: 5px;
  }
  .yt-btn {
    align-items: center;
    /* margin-left: 50px; */
    margin-top: 0%;
    max-width: 250px;
  }

  .ytslider {
    margin: 10px;
    height: 200px;
  }
  .hero-imgcont {
    min-width: 100%;
  }
}

@media only screen and (min-width: 100px) and (max-width: 431px) {
  .yt-h1 {
    margin-top: 0%;
    font-size: 18px;
  }
  .yt-h1 .p-span {
    text-align: center;
    padding: 5px;
    color: rgb(213, 45, 45);
    border-radius: 100%;
    font-size: 20px;
    margin-top: 5px;
  }
}
