.invest-button {
  background-color: #005978; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
  border-radius: 10px;
}
.disable-copy {
  user-select: none;
}
/* Reduce the button size and margin for smaller screens */
@media screen and (max-width: 768px) {
  .invest-button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 2px;
  }
}
/* Further reduce the button size and margin for even smaller screens */
@media screen and (max-width: 480px) {
  .invest-button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 1px;
  }
}
.invest-button:hover {
  background-color: #3e8e41; /* Dark green */
}

.hom{
  max-width: 100%;
  height: 30rem;
  width:60rem;
}
@media only screen and (max-width: 768px) {
  .hom{
    max-width: 80%;
    height: 20rem;
  }
}

@media only screen and (max-width: 480px) {
  .hom{
    max-width: 100%;
    height: 20rem;
  }
}
.explore{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
   background-color:#e1f4fb;
  border-radius: 10px;
  font-family: Poppins,sans-serif;
  box-sizing: border-box;
  max-width: 100%;
  }
.add{
  margin: 20px;
  color:#005978;
  font-weight: bold;
  font-family: Poppins,sans-serif;
}
.add:hover{
  color: black;
  transition: ease-in-out;
  transform-style: preserve-3d;
}
.ex{
  margin: 20px;
  font-size: large;
  font-weight: bold;
}
.start {
  text-align: center;
  margin-top: 40px;
  flex: 1;
  margin-right: 20px;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  width: 100%;
}
h1{
  margin: 0;
  font-size: 48px;
  font-weight: bold;
  font-family: Poppins, sans-serif;
}
p{
  font-size: 18px;
  line-height: 1.5;
}
.image-container {
  flex: 1;
}
.simh{ 
  display: block;
  max-width: 100%;
  height:22rem;
  border-radius: 20px;
  width: 100%;
}
.simhh{
  display: block;
  max-width: 100%;
  height:full;
  border-radius: 30px;
}
@media only screen and (max-width: 767px) {
  .simh {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .explore {
    flex-direction: column;
  }
  .start {
    width: 100%;
    
  }
  .add, .ex {
    margin: 20px;
    text-align: center;
  }
}
.but{
  background-color: #005978;
    color: whitesmoke;
    border: none;
    border-radius: 4px;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
}
.vid {
  position:relative;
  height: 30rem;
  margin-top: 0;
  margin: 0 auto;
  max-width:100%;
}
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height: 30rem;
  object-fit: cover;
  opacity: 0.8;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.buut{
background-color: #005978;
color: whitesmoke;
border: none;
  padding: 15px 24px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}
.newss{
  color: #005978;
  font-family: Poppins,sans-serif;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 40px;
  font-weight: bold;
  background-color: rgb(208, 231, 250);
  margin-bottom: 8px;
}
@media (max-width:600px) {
  .newss{
    font-size: 1.5rem;
     text-align:center;
     background-color: aliceblue;
    margin-top: 20px;
    font-weight: bold;
    font-family: Poppins,sans-serif;
  }
}
.ss{
  color: #005978;
  font-family: Poppins,sans-serif;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 40px;
  font-weight: bold;
  margin-bottom: 8px;
}
@media (max-width:600px) {
  .ss{
    font-size: 1.5rem;
     text-align:center;
    margin-top: 0px;
    font-weight: bold;
    font-family: Poppins,sans-serif;
  }
}
.beni{
 margin-top: 40px;
  text-align: center;
  
}
.benifit{
  margin-top: 40px;
  color: #005978;
  font-family: Poppins,sans-serif;
}
.ch{
  color: #005978;
  font-weight: bold;
  font-family: Poppins,sans-serif;
}
.section{
  position: relative;
  width: 100%;
  height: 200px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color:whitesmoke;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  margin-bottom: 20px;
 }
.section:hover .icon {
  transform: scale(1.2);
  z-index: 1;
}
.icon {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 40px;
  margin-right: 20px;
  color: #d54c1f;
  transition: transform 0.2s ease-in-out;
  z-index: 0;
}
.sf{
  position: relative;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #005978;
  margin-right: 20px;
  z-index: 2;
  font-family: Poppins,sans-serif;
}
.txt{
  position: relative;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .txt {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .txt {
    font-size: 17px;
    margin-bottom: 18px;
  }
}

@media only screen and (min-width:1300px) and (max-width: 1500px) {
  .searchbar{
    max-width: fit-content;
  }
  /* .hero-box{
    display: flex;
    flex-direction: column;
  } */
  .hero-slider {
    max-width: fit-content;

  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .txt {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  
}

@media only screen and (min-width: 1280px) {
  .txt {
    font-size: 20px;
    margin-bottom: 24px;
  }
}
.saf {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.col-md-6 {
  width: 50%;
  padding: 0 15px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .col-md-6 {
    width: 100%;
    padding: 0;
  }
}
.conta{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
   background-color:#e1f4fb;
  border-radius: 10px;
  font-family: Poppins,sans-serif;
  box-sizing: border-box;
  max-width: 100%;
}
.parent-element-of-call {
  display: flex;
  flex-wrap: wrap;

}
.call{
  text-align: center;
  align-items: center;
  margin-top: 40px;
  flex: 1;
  margin-right: 20px;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  width: 100%;
}
.home-loan-headerr {
  background-image: url('../assets/svg/shake.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  height: 30rem; /* set the height as per your requirement */
  position: relative;
  opacity: 0.8;
 
}

.home-loan-headerr h1 {
  color: #fff;
  font-size: 60px; /* set the font size as per your requirement */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px #000; /* add a text-shadow effect to the text */
}

.home-loan-headerr p {
      color: #fff;
      font-size: 1.5rem;
      margin-top: 1rem;
      text-align: left; /* Add this line */
}
.bbb {
  background-color: #005978;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.bbb:hover {
  background-color: rgb(126, 16, 16);
}

/* Media queries for responsive design */

@media only screen and (max-width: 768px) {
  .home-loan-headerr {
    height: 300px; /* adjust the height for smaller screens */
  }
  .home-loan-headerr h1 {
    font-size: 40px; /* adjust the font size for smaller screens */
  }

}

@media only screen and (max-width: 576px) {
  .home-loan-header {
    height: 200px; /* adjust the height for even smaller screens */
  }

  .home-loan-headerr p {
      font-size: 0.8rem;
      margin: 1rem 0 0 0;
    }
  .home-loan-headerr h1 {
    font-size: 30px; /* adjust the font size for even smaller screens */
  }
}
.contoffer {
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  overflow: hidden;
  max-width: 100%;
  align-items:end;
}

.offer {
  font-size: 28px;
  font-weight: bold;
  color: brown;
  text-transform: uppercase;
  animation: offer-animation 12s ease-in-out infinite;
}

@keyframes offer-animation {
 0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.pro{
  color: #005978;
  font-family: Poppins,sans-serif;
  text-align: center;
  font-size: 1.9rem;
  margin-top: 10px;
  font-weight: bold;
  background-color: rgb(188, 221, 248);

}
@media (max-width:600px) {
  .pro{
    font-size: 1.3rem;
     text-align:center;
     background-color: rgb(215, 232, 247);
    margin-top: 5px;
    font-weight: bold;
    font-family: Poppins,sans-serif;
  }
}
.electriccontainer {
  background-color: aquamarine;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
}

.electriccontent {
  flex: 1;
  padding-right: 20px;
  margin-left: 30px;
}

.electricimage {
  flex: 1;
  text-align: center;

}
.imm {
  max-width: 100%;
  height: auto;
}
@media only screen and (max-width: 768px) {
  .electriccontainer {
    flex-direction: column;
  }
  .electriccontent {
    margin-bottom: 20px;
  }
  .electricimage {
    display: block;
    margin-top: 20px;
  }
}
.electricdesignh2{
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.running-images {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.arrow {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background-color: #005978;
  color: #fff;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%; /* Add border-radius property to make it circular */
  width: 32px; /* Set width and height to define the size of the circle */
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.arrow-left {
  left: 0;
}

.arrow-right {
  right: 0;
}
.arrow span {
  display: inline-block;
}
.image-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  width: 100%;
  margin: 0 10px;
  scrollbar-width: none; /* Remove scrollbar for Firefox */
  -ms-overflow-style: none; /* Remove scrollbar for IE and Edge */

}

.image-container::-webkit-scrollbar {
  display: none; /* Remove scrollbar for Chrome and Safari */
}
.image-box {
  border: 1px solid #005978;
  padding: 10px;
  width: 20%;
  min-width: 250px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px; /* Add the desired gap between the boxes */
}

.running-images img {
  max-width: 80%;
  max-height: 90%;
}

@media (max-width: 768px) {
  .image-box {
    border: 1px solid #005978;
    width: 10%;
    min-width: 182px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    margin-right: 5px; /* Add the desired gap between the boxes */
  }
  
}

@media only screen and (min-width:100px) and (max-width:1300px){
  .hero-box{
    display: flex;
    flex-direction: column-reverse;
    margin: 0px;
  }
}
