@media (max-width: 768px) {
    .flex-col.md\:flex-row {
      flex-direction: column;
    }
  }
  .movefo{
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
     width:400px;
     margin-bottom: 30px;
    margin-right: 60px;
    display: flex;
    flex-direction: column;
    background: rgba(243, 243, 243, 0.6);
    backdrop-filter: blur(1.7606px);
    border-radius: 5.22371px;
    border: 2px solid #005978;
    border-radius: 5px;
    animation: zoomIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.moveimg{
  background-image: url('../assets/svg/packers-movers-charges-banner.jpeg ');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}
.moveform{
  padding: 20px;
} 
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input,
  select,
  textarea {
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;

  }
  .movebutton{
    background-color: #005978;
    color: whitesmoke;
    border: none;
    border-radius: 4px;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
  }
  
  .movebutton:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -1;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }
  
  .movebutton:hover:before {
    transform: translateX(0);
  }
  
  .movebutton:hover {
    background-color: rgb(176, 13, 13);
    color: white;
  }
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 10px;
    margin-left: 5px;
  }
  
  @media (max-width: 768px) {
    .movefo{
      padding: 10px;
      width: 80%;
    }
  }
  input:focus {
    border: 2px solid #007bff;
    box-shadow: 0px 0px 5px #007bff;
  }
  @media (max-width: 600px) {
    .moveform{
      max-width: 100%;
      padding: 10px;
    }
    
    input[type="text"],
    input[type="tel"],
    select,
    textarea {
      font-size: 14px;
    }
    
    .sharebutton[type="submit"] {
      font-size: 14px;
    }
  }
  .quote {
    background-color: #b2d5e8;
    padding: 30px;
    font-family: Poppins,sans-serif;
    text-align: center;
    box-shadow: 0px 0px 10px 0px #ccc;
    border-radius: 10px;
    font-weight: bold;
    color: #333;
    margin-top: 30px;
    font-size: 20px;
  }
  @media (max-width: 600px) {
    .ami{
      font-size: 1.5rem;
      text-align: center;
      margin-top: 10px;
      font-weight: bold;
      font-family: Poppins,sans-serif;
    }
  }
    
/* Style the amenities container */
.ami{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: 20px;
  }

/* Style the amenity boxes */
.amenityy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100px;
  margin:30px;
  padding: 2px;
  transition: all 0.3s ease-in-out;
}

/* Style the amenity images */
.amenityy img {
  width: 50%;
  height: auto;
  margin-bottom: 10px;
}

/* Style the amenity titles */
.amenityy p {
  font-size: 1rem;
  font-weight:600;
  text-align: center;
  color:#005978;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  /* Reduce the size of the amenity boxes */
  .amenityy {
    width: 250px;
    height: 200px;
    margin: 20px;
    padding: 5px;
  }

  /* Reduce the size of the amenity images */
  .amenityy img {
    width: 50%;
    height: auto;
    margin-bottom: 5px;
  }

  /* Reduce the font size of the amenity titles */
  .amenityy p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .amenityy {
    width: 100px;
    height: 100px;
    margin: 3px;
    padding: 10px;
    }
    
    /* Further reduce the size of the amenity images */
    .amenityy img {
    width: 30%;
    margin-bottom: 2px;
    }
    
    /* Further reduce the font size of the amenity titles */
    .amenityy p {
    font-size: 0.8rem;
    }
    }
    
    /* Add hover effect to amenity boxes */
    .amenityy:hover {
    transform: scale(1.05);
    background-color:#f6f1f1;
    box-shadow: 0 0 20px green;
    }
    
    /* Add transition to amenity images */
    .amenityy img {
    transition: all 0.3s ease-in-out;
    }
    .electriccontainer {
      background-color: rgb(234, 243, 240);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items:center;
    }
    
    .electriccontent {
      flex: 1;
      padding-right: 20px;
      margin-left: 30px;
    }
    
    .electricimage {
      flex: 1;
      text-align: center;
  
    }
    .imm {
      max-width: 100%;
      height: auto;
    }
    @media only screen and (max-width: 768px) {
      .electriccontainer {
        flex-direction: column;
      }
      .electriccontent {
        margin-bottom: 20px;
      }
      .electricimage {
        display: block;
        margin-top: 20px;
      }
    }