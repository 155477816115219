.home-loan-header {
    background-image: url('../assets/svg/shake.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    height: 350px; /* set the height as per your requirement */
    position: relative;
    opacity: 0.8;
  }
  .display-copy{
    user-select: none;
  }
  .home-loan-header h1 {
    color: #fff;
    font-size: 60px; /* set the font size as per your requirement */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 2px 2px #000; /* add a text-shadow effect to the text */
  }
  
  .home-loan-header p {
        color: #fff;
        font-size: 1.5rem;
        margin-top: 1rem;
        text-align: left; /* Add this line */
      
  }
  /* Media queries for responsive design */
  
  @media only screen and (max-width: 768px) {
    .home-loan-header {
      height: 300px; /* adjust the height for smaller screens */
    }
  
    .home-loan-header h1 {
      font-size: 40px; /* adjust the font size for smaller screens */
    }

  }
  
  @media only screen and (max-width: 576px) {
    .home-loan-header {
      height: 200px; /* adjust the height for even smaller screens */
    }
  
    .home-loan-header p {
        font-size: 0.8rem;
        margin: 1rem 0 0 0;
      }
    .home-loan-header h1 {
      font-size: 30px; /* adjust the font size for even smaller screens */
    }
  }
  
  .Emicont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
  }
  
  .emileft {
    flex-basis: 100%;
    text-align: center;
  }
  
  .emiright {
    flex-basis: 100%;
    margin-top: 30px;
  }
  
  .emih1{
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: #005978;
    text-align: start;
    margin-left: 20px;
  }
  @media only screen and (max-width: 767px) {
    .emih1 {
    font-size: 1.3rem;
    }
    }
    @media only screen and (max-width: 767px) {
        .emipp {
        text-align: left;
        margin-left: 5px;
        }
        }
      
  .emiform {
    display: flex;
    flex-direction: column;
    order: 2;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    border: 2px solid #ccc;
  }
  
  .emigroup {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-family: 'Montserrat', sans-serif;
  }
  
  input, textarea, select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    margin-top: 2px;
    background-color: #F2F2F2;
    color: #333;
    border: 2px solid #ccc;
  }
  
  .emibut {
    background-color: #2196F3;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .emibut {
    background-color: #0D47A1;
  }
  
  @media only screen and (min-width: 768px) {
    .left {
      flex-basis: 50%;
      text-align: right;
      padding-right: 30px;
    }
    
    .right {
      flex-basis: 50%;
      padding-left: 30px;
    }
  
    form {
      order: 1;
    }
  }
  
  /* Custom styles for form elements */
  
  .emiform {
    background-image: linear-gradient(to bottom right, #E3F2FD, #BBDEFB);
  }
  
  label {
    margin-top: 5px;
    margin-bottom: 2px;
  }
  
  input, textarea, select {
    font-family: 'Open Sans', sans-serif;
    transition: border-color 0.2s ease-in-out;
  }
  
  input:focus, textarea:focus, select:focus {
    outline: none;
    border-color: #2196F3;
  }
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  
  input[type="number"] {
  -moz-appearance: textfield;
  }
  
  select::-ms-expand {
  display: none;
  }
  
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ CSS */
  select {
  padding-right: 30px;
  }
  
  select::-ms-expand {
  display: none;
  }
  }
  .sp{
    color: #005978;
    font-family: Poppins,sans-serif;
    text-align: center;
    font-size: 1.9rem;
    margin-top: 60px;
    font-weight: bold;
    background-color: rgb(188, 221, 248);
  
 }
 @media (max-width:600px) {
    .sp{
      font-size: 1.3rem;
       text-align:center;
       background-color: rgb(215, 232, 247);
      margin-top: 40px;
      font-weight: bold;
      font-family: Poppins,sans-serif;
    }
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  margin-inline: 20px;
}

.card {
  width: calc(25% - 20px);
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card img {
  width: 100%;
  height: 200px;
  object-fit:fill;
  transition: transform 0.3s ease-in-out;
}
.card:hover img {
    transform: scale(1.1);
  }

.card-content {
  padding: 20px;
}

.card-content h3 {
  margin-top: 0;
}

.card-content p {
  margin-bottom: 20px;
}

.card-content a {
  display: inline-block;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
}

.card-content a:hover {
  background-color: #0069d9;
}
@media screen and (max-width: 768px) {
    .card {
      width: calc(50% - 20px);
    }
  }
  
  @media screen and (max-width: 576px) {
    .card {
      width: 100%;
    }
  }
  /* apply box-sizing to all elements */
*, *::before, *::after {
    box-sizing: border-box;
  }
  
  /* style for parent container */
  .emical {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f1f1f1;
  }
  
  /* style for image container */
  .image-container {
    flex: 1;
    margin: 0 0 20px 0;
  }
  
  /* style for image */
  .image-container img {
    width: 100%;
    height: auto;
  }
  
  /* style for content container */
  .content-container {
    flex: 1;
    margin: 0 0 20px 0;
    padding: 0 20px;
  }
  
  /* style for heading */
  .content-container h2 {
    font-size: 32px;
    margin: 0 0 10px 0;
    color: #333;
  }
  
  /* style for paragraph */
  .content-container p {
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 20px 0;
    color: #666;
  }
  
  /* style for button */
  .content-container button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* hover effect for button */
  .content-container button:hover {
    background-color: #3e8e41;
  }
  
  /* responsive styles */
  @media screen and (max-width: 767px) {
    .emical {
      flex-direction: column;
    }
    .image-container,
    .content-container {
      margin: 0 0 30px 0;
      text-align: center;
    }
  }
  