.blinking-text{
    animation: blink 1s linear infinite;
    color:red;
    margin: 40px;
    text-align: center;
    align-items: center;
    font-size:xx-large;
    margin-top: 10px;
    font-weight: bold;
    font-family: Poppins,sans-serif;
  }
   @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }