.fondcont {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  max-width: 95%;
  /* margin-left: 10px; */
}
.display-copy{
  user-select: none;
}
.fonbox {
  display: flex;
  max-width: 100%;
  justify-content: center;
  margin: 30px;
  text-align: center;
  padding: 0px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #ccc;
  border-radius: 10px;
}
.fonimg{
  max-width: 100%;
  height: auto;
  object-fit: contain;
  margin: 25px;
  border-radius: 50%;
  border: 5px solid #f2f2f2;
}
.fonboxdesp{
  width: fit-content;
}
p{
  font-size: 1rem;
  line-height: 1.5rem;
}
.fonbox:hover {
  transform: scale(1.05);
  box-shadow: 2px 5px 10px lightgray;
  cursor: pointer;
}
.Meet{
font-family: Poppins,sans-serif;
font-style: normal;
font-weight: bold;
font-size: 30px;
color: #005978;
margin-bottom: 20px;
/* identical to box height, or 125% */
}
.inv{
  font-size:xx-large;
  font-weight: bolder;
  letter-spacing: 2px;
  color: #780016;
  font-family: Poppins,sans-serif;
}
.about-us {
  max-width: 100%;
  margin-left: 20px;
  /* background-color:whitesmoke; */
  /* padding: 0px; */
  font-family: Arial, sans-serif;
  text-align: center;
  /* box-shadow: 0px 0px 10px 0px #ccc; */
  border-radius: 10px;
  margin-bottom: 30px;
  margin: 10px;
  }
 
.description {
  margin-bottom: 20px;
}
.description h1 {
  font-size: 30px;
  padding-top: 20px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bolder;
  letter-spacing: 2px;
  color: #005978;
  font-family: Poppins,sans-serif;
}
.description p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-family: Poppins,sans-serif;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
}
.founders {
  display: flex;
  justify-content:center;
  margin-bottom: 50px;
}
.founder{
  max-width: 45%;
  text-align: left;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #ccc;
  border-radius: 10px;
}
@media only screen and (max-width: 1300px) {
  .about-us{
    max-width: 100%;
  }
  .fondcont{
    width: 90%;
    max-width: fit-content;
    display: block;
    text-align: center;
    padding: 10px;
    margin-left: 5px;
  }
}
.fonbox{
  width: 90%;
  
}
.fonimg {
  width: 700px;
  margin-bottom: 20px;
  border-radius: 50%;
  border: 5px solid #f2f2f2;
}
.fonh{
  font-size: 24px;
  /* margin-bottom: 10px; */
  font-weight: bold;
  letter-spacing: 2px;
  color: #005978;
  text-align: left;
}
.detail{
  font-size: 18px;
  line-height: 1.5;
  color: #333;
}
.description{
  max-width: 97%;
}
.social-media {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: 80px;
  }
.social-media a {
  margin-right: 10px;
  font-size: 24px;
  color: #333;
  transition: all 0.2s ease-in-out;
}
.social-media a:hover {
  color: #47b5d7;
}
.quote {
  background-color: #b2d5e8;
  padding: 30px;
  max-width: 95%;
  margin: 10px;
  font-family: Poppins,sans-serif;
  text-align: center;
  box-shadow: 0px 0px 10px 0px #ccc;
  border-radius: 10px;
  color: #333;
  margin-top: 40px;
  font-size: 20px;
}
.employee-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px;
  width: 100%;
}
.employee {
  width: 300px;
  margin: 20px;
  text-align: center;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 2px 2px 5px lightgray;
  font-family: Poppins,sans-serif;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  color: #005978;
}
.employee:hover {
  transform: scale(1.05);
  box-shadow: 2px 5px 10px lightgray;
  cursor: pointer;
}
.employee-image {
padding-top:0px;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}
.employee-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Team{
  font-size: xx-large;
  font-style:inherit;
  font-weight: bolder;
  font-family: Poppins,sans-serif;
  color: #005978;
  padding-top: 15px;
}
.Des{
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-family: Poppins,sans-serif;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  }

@media only screen and (max-width: 720px) {
  .about-us{
    max-width: 100%;
  }
  .fondcont{
    max-width: 100%;
  }
  .fonbox{
    display: block;
    padding-top: 5px;
  }
  .fonimg{
    max-width: 90%;
    margin: 25px;
  }
  .social-media{
    margin-left: 10px;
  }
  .employee {
    max-width: 300px;
    height: auto;
    gap: 20px;
    padding-right: 5px;
  }
}
.soc{
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: bolder;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #005978;
  font-family: Poppins,sans-serif;
}
.detail{
  text-align: left;
  font-weight: bolder;
}
.employee h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
 
  letter-spacing: 2px;
  color: #005978;
  text-align: center;
}
.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 1rem; */
}
.subscribe form {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-top: 1rem;
}
.subscribe input[type="email"] {
  width: 70%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 1rem;
}
.subscribe button[type="submit"] {
  width: 25%;
  margin:10px;
  padding: 0.5rem;
  font-size: 1rem;
  background-color:#005978;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  

  .display-copy {
    max-width: 100%;
    padding: 0 10px; /* Add some padding to the sides */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  .fondcont{
    max-width: 100%;
  }
  .fonbox{
    display: block;
    padding-top: 5px;
  }
  .subscribe form {
    flex-direction: column;
    align-items: center;
  }
  .subscribe input[type="email"],
  .subscribe button[type="submit"] {
    width: 100%;
    margin-top: 1rem;
  }
  .fonimg{
    max-width: 85%;
  }
  .fonh{
    text-align: center;
  }
  .detail{
    text-align: center;
  }
  .description{
    justify-content: center;
    margin-right: 10px;
    text-align: left;
  }
}
.subscribe button[type="submit"]:hover {
  background-color: steelblue;
}
.social-media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.social-media-icons a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 10px;
  border-radius: 50%;
  /* background-color: #e68c8c; */
  color: #fff;
  transition: all 0.3s ease;
}
.social-media-icons a:hover {
  background-color: hsl(213, 48%, 86%); /* Change to the brand color of the social media platform */
  color: #fff;
}

/* @media only screen and (min-width:100px) and (max-width: 720px) {
  .employee-container{
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
} */