.property-features {
  margin-bottom: 20px;
}

.feature-group {
  margin-bottom: 10px;
  width: 200px;
  display: flex;
  flex-wrap: wrap;
}

.feature-group label {
  display: block;
  margin-bottom: 5px;
}

.feature-group select,
.feature-group input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.feature-group input[type="number"] {
  width: 60px;
}

.feature-group span {
  margin-left: 5px;
}