.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(245, 245, 245, 0.8); /* Adjust the opacity as needed */
  padding: 10px;
  margin-bottom: 1px;
  backdrop-filter: blur(11px); /* Adjust the blur radius as needed */
}
.disable-copy {
  user-select: none;
}

.logo img {
  height: 44px;
  width: auto;
  margin-left: 20px;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
@media (max-width: 1022px) {
  .hide-on-mobile {
    display: none;
  }
  .navbar{
      background-color: rgba(245, 245, 245, 0.8); /* Adjust the opacity as needed */

  }
  
  .nav-links li{
    display: none;
  }
  .logo {
    align-items: left;
    flex: 1;
    margin-left: 10px;
  }
  .hidden-nav{
    width: 100%;
  }
}

.nav-links li {
  margin-left: 30px;
  width: fit-content;
}

.nav-links a {
  text-decoration: none;
  color: #005978;
  font-weight: bold;
}
.nav-links button {
  text-decoration: none;
  color: #005978;
  font-weight: bold;
}
.nav-links a:hover {
  color: #666;
}

@media (max-width: 390px) {
 .logo{
  display: none;
 }
}
