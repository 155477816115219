.modal-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-container.show {
  display: block;
}
.form-containerr{
  background-color: #fefefe;
  margin: 15% auto;
  padding: 10px;
  border: 2px solid #888;
  width: 35%;
  border-radius: 5.22371px;
  animation: zoomIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  background: rgba(215, 213, 213, 0.6);
  backdrop-filter: blur(10.7606px);
  border-radius: 5.22371px;
  animation: zoomIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

  button[type="submitt"] {
    background-color:#005978;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submitt"]:hover {
    background-color: #3e8e41;
  }
  .close-icon{
    position: absolute;
    top:0px;
    right: 0px;
    border: none;
    background-color: transparent;
    font-size: 25px;
    cursor: pointer;
  }
  
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

button[type="submitt"] {
  background-color:#005978;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submitt"]:hover {
  background-color: #3e8e41;
}

.close-icon{
  position: absolute;
  top:0px;
  right: 0px;
  border: none;
  background-color: transparent;
  font-size: 25px;
  cursor: pointer;
}
/* Adjust properties for screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .form-containerr{
    margin: 20% auto;
    width: 80%;
    padding: 5px;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

button[type="submitt"] {
  background-color:#005978;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submitt"]:hover {
  background-color: #3e8e41;
}

.close-icon{
  position: absolute;
  top:0px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 25px;
  cursor: pointer;
}
#sign-in-button {
  display: none;
}
.otp-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.otp-input-container input {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  border: 1px solid #6d6969;
  border-radius: 4px;
  outline: none;
}

@media (max-width: 600px) {
  .otp-input-container input {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
}
