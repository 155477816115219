.video-section {
    margin: 20px 0;
  }
  .video-section h2 {
    margin-top: 0;
    font-size: 24px;
  }
  
  .video-section video {
    width:100;
    max-height: 30rem;
  }
  
  .video-section p {
    margin-top: 10px;
    font-size: 14px;
    color: #666; 
  }
  
  @media only screen and (min-width: 768px) {
    /* Adjust styles for screens with a minimum width of 768px */
    .video-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .video-section video {
      max-width: 80%;
      
   }
    
    .video-section p {
      max-width: 80%;
    }
  }
  