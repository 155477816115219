.fo{
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
     width:400px;
     margin-bottom: 30px;
    margin-right: 60px;
    display: flex;
    flex-direction: column;
    background: rgba(243, 243, 243, 0.6);
    backdrop-filter: blur(5.7606px);
    border-radius: 5.22371px;
    border: 2px solid #005978;
    border-radius: 5px;
}
.backi{
  background-image: url('../assets/svg/feedbackimg.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  user-select: none;
}
.for  {
  padding: 20px;
} 
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input,
  select,
  textarea {
    border: none;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;

  }
  .sharebutton {
    background-color: #005978;
    color: whitesmoke;
    border: none;
    border-radius: 4px;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
  }
  
  .sharebutton:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -1;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }
  
  .sharebutton:hover:before {
    transform: translateX(0);
  }
  
  .sharebutton:hover {
    background-color: rgb(176, 13, 13);
    color: white;
  }
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 10px;
    margin-left: 5px;
  }
  
  @media (max-width: 768px) {
    .fo{
      padding: 10px;
      width: 80%;
    }
  }
  input:focus {
    border: 2px solid #007bff;
    box-shadow: 0px 0px 5px #007bff;
  }
  @media (max-width: 600px) {
    .for{
      max-width: 100%;
      padding: 10px;
    }
    
    input[type="text"],
    input[type="tel"],
    select,
    textarea {
      font-size: 14px;
    }
    
    .sharebutton[type="submit"] {
      font-size: 14px;
    }
  }